// Select

select.form-control {
	border-radius: 3px;
	background-color: $white;
	outline: 0px !important;
	box-shadow: none !important;
	font-size: 14px;
	height: 40px;
	border: 1px solid darken($gray,20) !important;
	&.white {
		background-color: $white !important;
	}
	&.green {
		background-color: $prod-green2 !important;
		color: $white !important;
	}
	&.gray {
		background-color: $gray !important;
	}
}