// Profile

.modal-password {
	@include modal();
	.modal-dialog {
		max-width: 800px;
	}
}

.modal-profile {
	@include modal();
	.modal-dialog {
		max-width: 800px;
	}
}

#profile, #password {
	padding: 30px;
	padding-top: 0px;
	form {
		width: 95%;
		margin: 0 auto;
	}
	select {
		border: 0px !important;
	}
	.image-left {
		width: 100%;
		height: auto;
		object-fit: contain;
		max-width: 300px;
		@media (max-width: 991px) {
			display: none;
		}
	}
	input {
		border-radius: 10px !important;
	}
	label {
		font-family: $font-family-light;
		font-size: 16px;
		margin-top: 10px;
	}
	.col-md-6, .col-12 {
		padding: 0px;
		margin: 0px;
	}
	h3 {
		color: $prod-menu2;
		font-size: 22px;
		font-family: $font-family-bold;
		margin: 0px;
		padding: 0px;
		margin-bottom: 10px;
	}
	button {
		border-radius: 10px !important;
		font-size: 20px;
		margin: 0 auto;
		margin-top: 10px;
		background-color: transparent !important;
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		text-transform: uppercase;
		font-family: $font-family-bold;
		&.btn-green {
			color: $green !important;
		}
		&.btn-close {
			color: $black !important;
		}
	}
}