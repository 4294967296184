// Input

textarea.form-control {
	border-radius: 10px !important;
	background-color: $input !important;
	outline: 0px !important;
	box-shadow: none !important;
	border: 1px solid darken($gray,20) !important;
	font-size: 14px;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	resize: none;
	&.input-white {
		background-color: $white !important;
	}
}