// Variables

$gray: #d2d3d6;
$blue: #394978;
$blue2: #3b4f88;
$black: #222d32;
$black2: #222;
$white: #fff;
$input: #f1f1f1;
$active: #0d1214;
$danger: #d20b06;
$green: #88c440;
$green2: #00833e;
$orange: #FB8C00;
$light: #F5F5F5;
$yellow: #FFC107;
$black2: #1a1a1c;
$default: #75757d;
$default2: #48484a;
$bg: #f6f6f6;
$cyan: #e7faff;
$gray2: #969aab;
$paperclip: #e7faff;
$promo-blue: #46abfb;
$promo-green: #49c557;

$prod-green: #779a0a;
$prod-green2: #099896;
$prod-green3: #53b7b5;
$prod-gray: #606060;
$prod-gray2: #eaeaea;
$prod-gray3: #f2f2f2;
$prod-gray4: #d4d5db;
$prod-blue1: #3654e8;
$prod-blue2: #009bdb;
$prod-light: #5c5b60;
$prod-orange: #daa509;
$prod-black: #000;
$prod-purple: #2a4055;
$prod-table-green: #fafbfd;
$prod-menu: #515d97;
$prod-menu2: #2a3865;

$fonts-path: "../../assets/fonts/";
$width-menu: 210px;
$header-height: 127px;
$footer-height: 32px;

$font-family: "Lato-Regular";
$font-family-bold: "Lato-Bold";
$font-family-black2: "Lato-Black";
$font-family-light: "Lato-Light";
$font-family-black: "BarlowCondensed-Bold";
$font-family-regular: "BarlowCondensed-Regular";

$height-banner: 90px;
$height-item: 37px;

$total_1: #22cd68;
$total_2: #10767a;
$total_3: #1a89e5;
$red_td: #f28c8d;

$_item_reporte1: #d20b06;
$_item_reporte2: #d1ad1b;
$_item_reporte3: #1bd156;
$_item_reporte4: #111cd0;

$public_green: #00996b;
$public_green2: #115b42;
$public_gray: #f0f0f0;

$new_green: #89c43f;
$new_orange: #fb5d2e;