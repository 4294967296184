// View Payment

.modal-add-address {
	@include modal();
	.modal-dialog {
		max-width: 800px;
	}
}

#modal-add-address {
	padding: 30px;
	h4 {
		font-family: $font-family-bold;
		font-size: 16px;
		border-bottom: 2px solid $public_green;
		padding-bottom: 10px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.btn-green {
		background-color: $public_green !important;
		outline: 0px !important;
		text-transform: uppercase;
		color: $white !important;
		border: 0px !important;
		border-radius: 30px !important;
		padding: 10px;
		width: 100%;
		font-size: 16px;
		font-family: $font-family-bold;
		max-width: 200px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.form-group {
		input, select, textarea {
			background-color: $public_gray !important;
			border-radius: 5px !important;
			border: 0px !important;
		}
		label {
			font-size: 16px;
		}
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	.container-document {
		display: flex;
		select {
			width: 70px;
		}
		.form-group-input {
			flex: 1;
			margin-left: 10px;
		}
	}
}

#public-view-payment {
	padding-bottom: 70px;
	.splendor-address-container {
		display: flex;
		border-radius: 5px;
		margin-bottom: 5px;
		background-color: $public_gray;
		margin-top: 10px;
		img {
			width: 23px;
			height: 23px;
			object-fit: contain;
		}
		a {
			margin-right: 10px;
			display: inline-block;
		}
		.container-data {
			padding: 5px;
			p {
				margin: 0px;
			}
		}
		.check {
			cursor: pointer;
			background-color: $white;
			width: 25px;
			height: 25px;
			border: 1px solid $black;
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}
	}
	.container-payment {
		padding: 20px;
		max-width: 1300px;
		margin: 0 auto;
		padding-top: 35px;
	}
	.no-addresses {
		font-family: $font-family-bold;
		font-size: 18px;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.container-add-address {
		display: inline-flex;
		text-decoration: none !important;
		color: $black !important;
		justify-content: flex-end;
		align-items: center;
		p {
			margin: 0px;
			font-size: 17px;
			font-family: $font-family-bold;
		}
		img {
			width: 22px;
			height: 22px;
			object-fit: contain;
			@include recolor($public_green);
			margin-left: 10px;
		}
	}
	.container-total {
		max-width: 350px;
		border: 1px solid $gray;
		background-color: $gray;
		margin: 0 auto;
		h5 {
			text-transform: uppercase;
			color: $white;
			font-family: $font-family-bold;
			background-color: #222;
			padding: 10px;
			margin: 0px;
		}
		table {
			width: 100%;
			td {
				width: 50%;
				padding: 5px;
				font-size: 15px;
				padding-top: 3px;
				padding-bottom: 3px;
			}
			h3 {
				margin: 0px;
				font-size: 16px;
				font-family: $font-family-bold;
				text-transform: uppercase;
				color: $black;
				margin-top: 5px;
				margin-bottom: 5px;
			}
			.bold {
				font-family: $font-family-bold;
			}
		}
	}	
	h3 {
		font-family: $font-family-bold;
		color: $public_green2;
		font-size: 25px;
	}
	h4 {
		font-family: $font-family-bold;
		font-size: 16px;
		border-bottom: 2px solid $public_green;
		padding-bottom: 10px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.container-data {
		padding-left: 40px;
	}
	.container-document {
		display: flex;
		select {
			width: 70px;
		}
		.form-group-input {
			flex: 1;
			margin-left: 10px;
		}
	}
	.btn-green {
		background-color: $public_green !important;
		outline: 0px !important;
		text-transform: uppercase;
		color: $white !important;
		border: 0px !important;
		border-radius: 30px !important;
		padding: 10px;
		width: 100%;
		font-size: 16px;
		font-family: $font-family-bold;
		max-width: 200px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.item-number {
		display: flex;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
		justify-content: space-between;
		span {
			background-color: $public_green;
			border-radius: 5px;
			color: $white;
			text-align: center;
			padding: 4px;
			width: 30px;
			margin-right: 10px;
		}
		p {
			margin: 0px;
			font-family: $font-family-bold;
			font-size: 18px;
		}
		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			cursor: pointer;
		}
		& > div {
			display: flex;
			align-items: center;
		}
	}
	.form-group {
		input, select, textarea {
			background-color: $public_gray !important;
			border-radius: 5px !important;
			border: 0px !important;
		}
		label {
			font-size: 16px;
		}
	}
	label {
		font-family: $font-family-bold;
		font-size: 14px;
	}
	.btn-delivery {
		display: flex;
		background-color: $gray !important;
		outline: 0px !important;
		text-align: left;
		align-items: center;
		margin-bottom: 15px;
		border: 0px !important;
		box-shadow: none !important;
		width: 100%;
		max-width: 700px;
		border-radius: 5px;
		padding: 10px;
		&.active {
			background-color: $public_green2 !important;
			p {
				color: $white;
			}
			.title-delivery {
				color: $white;
			}
		}
		.item-check {
			width: 25px;
			height: 25px;
			border-radius: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $white;
			border: 1px solid darken($gray,20);
			margin-right: 15px;
			img {
				width: 15px;
				height: 15px;
				object-fit: contain;
			}
		}
		.title-delivery {
			color: $black;
			font-size: 16px;
			font-family: $font-family-bold;
		}
		p {
			margin: 0px;
			font-size: 15px;
		}
	}
	.container-check {
		display: flex;
		align-items: center;
		font-size: 16px;
		cursor: pointer;
		@include noSelect();
	}
	.container-circle-green {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		border: 1px solid darken($gray,20);
		margin-right: 10px;
		.circle-green {
			width: 14px;
			height: 14px;
			border-radius: 7px;
			background-color: $public_green;
		}
	}
	.btn-payment-method {
		border: 1px solid $gray !important;
		border-radius: 5px !important;
		box-shadow: none !important;
		outline: 0px !important;
		background-color: $white !important;
		font-family: $font-family-bold;
		width: 100%;
		padding: 8px;
		font-size: 16px;
		text-align: left;
		padding-left: 20px;
		padding-right: 20px;
		img {
			width: 25px;
			height: 25px;
			object-fit: contain;
			margin-right: 10px;
		}
		&.active {
			background-color: $public_green2 !important;
			color: $white !important;
		}
	}
	.container-summary {
		hr {
			height: 1px !important;
			background-color: $public_gray;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		h6 {
			font-size: 16px;
			border-bottom: 2px solid $public_green;
			padding-bottom: 10px;
			margin-bottom: 10px;
			margin-top: 20px;
			font-family: $font-family-bold;
		}
		p {
			margin: 0px;
			font-size: 15px;
			&.bold {
				font-family: $font-family-bold;
			}
		}
		.amount {
			background-color: $public_gray;
			border-radius: 5px;
			padding: 10px;
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			margin-bottom: 20px;
			p {
				font-family: $font-family-bold;
				font-size: 17px;
			}
		}
	}
	.data-to-payment {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		p {
			font-size: 15px;
			margin: 0px;
			margin-bottom: 3px;
			&.bold {
				font-family: $font-family-bold;
				font-size: 16px;
			}
		}
	}
	.title-data-payment {
		text-align: center;
		font-family: $font-family-bold;
		font-size: 16px;
	}
	.reference input {
		background-color: transparent !important;
		border-radius: 0px !important;
		text-align: center;
		color: $public_green;
		@include placeholderColor($public_green);
		font-size: 18px;
		border-bottom: 1px solid $black !important;
		max-width: 600px;
		margin: 0 auto;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	.label-datepicker {
		margin-bottom: 10px;
	}
	.container-datepicker {
		background-color: $public_gray !important;
		border-radius: 5px !important;
		border: 0px !important;
	}
	.container-photo {
		background-color: $public_gray;
		border-radius: 5px;
		overflow: hidden;
		.title-photo {
			text-align: center;
			border-bottom: 1px solid darken($public_gray,20);
			padding: 10px;
			font-size: 14px;
			margin: 0px;
		}
		.container-padding {
			padding: 10px;
		}
		.container-paper-clip {
			button {
				background-color: transparent !important;
				border-radius: 5px !important;
				box-shadow: none !important;
				border: 1px dashed $black !important;
				text-align: center !important;
				font-size: 25px;
				height: auto;
				min-height: 150px;
			}
		}
		.photo {
			position: relative;
			width: 100px;
			height: 100px;
			margin: 0 auto;
			.container-close-photo {
				width: 20px;
				height: 20px;
				border-radius: 10px;
				background-color: $black;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				position: absolute;
				right: -5px;
				top: -5px;
				img {
					width: 10px;
					height: 10px;
				}
			}
			img {
				width: 100px;
				height: 100px;
				object-fit: cover;
			}
		}
	}
}