// View Cart

#public-view-cart {
	padding-bottom: 70px;
	.container-cart {
		padding: 20px;
		max-width: 1300px;
		margin: 0 auto;
		padding-top: 35px;
	}
	.container-total {
		max-width: 350px;
		@media (max-width: 767px) {
			max-width: 100%;
			width: 100%;
		}
		border: 1px solid $gray;
		margin: 0 auto;
		margin-top: 10px;
		table {
			width: 100%;
			tr {
				td {
					padding: 8px;
					font-size: 15px;
				}
				border-bottom: 1px solid $gray !important;
			}
			tr:last-child {
				border-bottom: 0px !important;
			}
		}
		.container-padding {
			padding: 10px;
			border-top: 1px solid $gray !important;
		}
		h4 {
			text-transform: uppercase;
			color: $white;
			font-family: $font-family-bold;
			background-color: #222;
			padding: 10px;
			margin: 0px;
		}
		.btn-green {
			background-color: $public_green !important;
			outline: 0px !important;
			text-transform: uppercase;
			color: $white !important;
			border: 0px !important;
			border-radius: 20px !important;
			padding: 10px;
			width: 100%;
			font-size: 20px;
			font-family: $font-family-bold;
		}
	}	
	.no-items {
		background-color: $gray;
		border-radius: 10px;
		color: $black;
		margin: 0 auto;
		margin-top: 10px;
		padding: 5px;
		font-family: $font-family-light;
		text-align: center;
	}
	h3 {
		font-family: $font-family-bold;
		color: $public_green2;
		font-size: 25px;
	}
	.btn-back {
		background-color: $yellow !important;
		outline: 0px !important;
		text-transform: uppercase;
		color: $black !important;
		border: 0px !important;
		border-radius: 20px !important;
		padding: 10px;
		width: 100%;
		font-size: 20px;
		font-family: $font-family-bold;
		margin-top: 20px;
		margin-bottom: 20px;
		max-width: 350px;
		@media (max-width: 767px) {
			max-width: 100%;
			width: 100%;
		}
	}
	table.table-products {
		width: 100%;
		border: 1px solid darken($gray,20);
		margin-top: 10px;
		margin-bottom: 20px;
		th {
			padding: 10px;
			background-color: $gray;
			border-right: 1px solid darken($gray,20);
			border-bottom: 1px solid darken($gray,20);
			font-family: $font-family-bold;
			&.td-responsive {
				@media (max-width: 767px) {
					display: none;
				}
			}
		}
		td {
			padding: 10px;
			border-right: 1px solid darken($gray,20);
			border-bottom: 1px solid darken($gray,20);
			font-size: 14px;
			&.td-responsive {
				@media (max-width: 767px) {
					display: none;
				}
			}
			button {
				background-color: transparent !important;
				outline: 0px !important;
				border: 0px !important;
				box-shadow: none !important;
				img {
					width: 20px;
					height: 20px;
					object-fit: contain;
				}
			}
		}
		.price {
			font-family: $font-family-bold;
		}
		.form-group {
			margin-bottom: 0px;
			max-width: 80px;
			margin: 0 auto;
			input {
				text-align: center;
			}
		}
		.container-photo {
			display: flex;
			align-items: center;
			img {
				width: 50px;
				height: 50px;
				margin-right: 10px;
				@media (max-width: 767px) {
					display: none;
				}
			}
		}
	}
}