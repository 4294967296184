// View Search

#public-view-search {
	.container-search {
		padding: 20px;
	}
	h3.title-green {
		@include titleGreen();
		width: 100%;
		margin-top: 10px;
		margin-bottom: 30px;
	}
	.container-slider-secondary {
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.item-product {
		@include product();
	}
	a.link-product {
		text-decoration: none !important;
	}
	.message-no-items {
		width: 95%;
		border-radius: 10px;
		background-color: $prod-gray2;
		padding: 10px;
		text-align: center;
		font-family: $font-family-light;
		margin: 0 auto;
		margin-bottom: 30px;
	}
}