// Register

#register {
	background-color: $new_green;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	.container-black {
		// background-color: rgba(0,0,0,0.3);
		width: 100%;
		height: 100vh;
		overflow: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	select {
		border: 0px !important;
	}
	.select-document {
		max-width: 80px;
		margin-right: 15px !important;
	}
	.container-document {
		display: flex;
		& > div:not(.select-document) {
			flex: 1;
		}
	}
	.container-bg {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: left top;
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		transition: 1s;
		@media (max-width: 991px) {
			display: none;
		}
		h3 {
			font-size: 35px;
			color: $white;
			font-family: $font-family-bold;
			text-transform: uppercase;
			max-width: 420px;
			margin-left: 30px;
			margin-top: 60px;
			span {
				color: $new_green;
				font-family: $font-family-bold;
			}
		}
		h4 {
			color: $prod-gray;
			font-size: 22px;
			max-width: 420px;
			margin-left: 20%;
			margin-top: 15px;
			letter-spacing: 1px;
			line-height: 35px;
		}
	}	
	.row {
		margin: 0px !important;
	}
	.container-form {
		min-height: 80vh;
		max-width: 80%;
		width: 100%;
		box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
		@media (max-width: 991px) {
			max-width: 100%;
			min-height: 100vh;
			border-radius: 0px;
			height: 100vh;
			overflow-y: auto;
		}
		border-radius: 10px;
		overflow: hidden;
	}
	height: 100vh;
	.arrow-left-green {
		width: 30px;
		height: 30px;
		object-fit: contain;
	}
	.title-green {
		font-family: $font-family-bold;
		color: $blue2;
		text-align: center;
		font-size: 25px;
	}
	.row-title {
		margin-top: 25px !important;
		display: flex;
		img {
			margin-right: 20px;
			margin-left: 15px;
		}
		a {
			text-decoration: none !important;
		}
	}
	.subtitle {
		color: $blue2;
		font-family: $font-family;
		margin: 0px;
		margin-bottom: 15px;
		margin-left: 15px;
		margin-top: 15px;
		font-size: 18px;
	}
	.container-btn-submit {
		@media (max-width: 991px) {
			text-align: center;
		}
	}
	form, #form-validate {
		background-color: $white;
		.container-logo {
			padding: 20px;
			padding-top: 0px;
			text-align: center;
			img {
				width: 100%;
				max-width: 170px;
				margin: 0 auto;
			}
		}
		.form-group {
			label {
				color: $prod-gray;
				font-size: 14px;
			}
			.input-group-prepend {
				img {
					width: 30px;
					height: 30px;
					object-fit: contain;
					margin-top: 5px;
				}
			}
			input {
				border: 0px !important;
				font-size: 14px;
				border-radius: 0px !important;
				background-color: $white !important;
			}
			.form-group {
				margin: 0px;
				width: 90%;
			}
			border-radius: 10px;
			margin-bottom: 15px;
			padding: 5px;
			border: 1px solid $gray;
		}
		.container-input {
			padding: 20px;
			padding-left: 30px;
			padding-right: 30px;
			width: 100%;
			border-radius: 10px;
			margin: 0 auto;
		}
		input {
			font-size: 13px;
		}
		button:not(.button-validate) {
			border-radius: 3px !important;
			font-size: 18px;
			margin: 0 auto;
			margin-top: 10px;
			background-color: $blue !important;
			padding-top: 10px !important;
			padding-bottom: 10px !important;
			font-family: $font-family-bold;
			padding-top: 15px !important;
			padding-bottom: 15px !important;
			width: 200px;
			text-transform: uppercase;
		}
	}
	&.validation-user {
		.container-code {
			display: flex;
			margin: 0 auto;
			.form-group {
				margin-bottom: 0px;
				flex: 1;
				margin-right: 10px;
				padding: 5px !important;
			}
			button.button-validate {
				margin-top: 0px !important;
				height: 51.6px;
				min-width: auto !important;
				width: 100px !important;
				padding: 0px !important;
				background-color: $blue !important;
				text-transform: uppercase;
				color: $white !important;
				border-radius: 10px !important;
			}
		}
		.subtitle-validate {
			margin-bottom: 20px;
			margin-top: 10px;
			font-size: 15px;
		}
		.response {
			text-transform: uppercase;
			font-size: 13px;
			font-family: $font-family-bold;
			&.response-blue, .response-blue {
				color: $blue;
				font-family: $font-family-bold;
			}
			&.response-red, .response-red {
				color: $danger;
				font-family: $font-family-bold;
			}
			&.top {
				text-align: center;
				margin-top: 10px;
			}
		}
		.resend-code {
			display: inline-block;
			text-decoration: none !important;
			font-family: $font-family-bold;
			color: $blue;
			margin-bottom: 15px;
		}
		.logout {
			cursor: pointer;
			text-align: center;
			margin-top: 20px;
			margin-bottom: 40px;
			color: $danger;
			font-family: $font-family-bold;
		}
		.container-finish {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			.container-row {
				flex: 1;
			}
		}
	}
}