// Layout

#public-layout {
	display: flex;
  	flex-direction: column;
  	min-height: 100vh;
  	background-color: $white;
  	#container-public-layout {
  		flex: 1;
  	}
  	.navigation-bar {
  		display: flex;
  		background-color: $gray;
  		padding: 20px;
  		text-align: center;
  		align-items: center;
  		justify-content: center;
  		.item-navigation {
  			margin-right: 10px;
  			margin-left: 10px;
  			p {
  				margin: 0px;
  				padding: 0px;
  				font-size: 18px;
  				color: darken($gray,40);
  				&.bold {
  					font-family: $font-family-bold;
  					color: $black;
  				}
  			}
  			a {
  				text-decoration: none !important;
  			}
  		}
  	}
  	.item-secondary {
		width: 100%;
	}
	.container-slider-secondary {
		margin-top: 30px;
		margin-bottom: 30px;
		width: 95%;
		overflow: hidden;
		@media (max-width: 991px) {
			width: 100%;
		}
	}
	.swiper-pagination-bullet {
		width: 17px;
		height: 17px;
		border-radius: 8.5px;
		&.swiper-pagination-bullet-active {
			background-color: $yellow;
		}
	}
}