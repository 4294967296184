// Public Home

#public-home {
	.container-header {
		background-size: cover;
		background-position: center center;
		width: 100%;
		position: relative;
		min-height: 400px;
		background-repeat: no-repeat;
		a {
			text-decoration: none !important;
			img {
				width: 300px;
				position: absolute;
				left: 40px;
				bottom: 40px;
			}
		}
	}
	.item-slider {
		width: 100%;
	}
	.item-anuncio {
		height: 50vh;
		padding: 20px;
	}
	h3 {
		@include titleGreen();
	}
	.row-products {
		width: 95%;
		margin: 0px !important;
		.col-md-4, .col-md-6 {
			padding: 0px !important;
		}
		@media (max-width: 991px) {
			width: 100%;
		}
	}
	.message-no-items {
		width: 100%;
		border-radius: 10px;
		background-color: $prod-gray2;
		padding: 10px;
		text-align: center;
		font-family: $font-family-light;
		@media (max-width: 991px) {
			margin: 0 auto;
			margin-bottom: 30px;
			width: 95%;
		}
	}
	.container-fixed-advertisings {
		@media (min-width: 992px) {
			display: flex;
			flex-direction: column;
			position: absolute;
			left: 55px;
			top: 0px;
			&.active {
				position: fixed;
				left: 40px;
			}
		}
	}
	.container-loading {
		text-align: center;
		color: $public_green2;
		margin-top: 40px;
		margin-bottom: 40px;
	}
	.item-combo {
		@include combo();
	}
	.item-product {
		@include product();
	}
	a.link-product {
		text-decoration: none !important;
	}
}

.modal-success {
	@include modal();
	.modal-dialog {
		max-width: 450px;
	}
	.modal-body {
		border-top-right-radius: 20px !important;
		border-top-left-radius: 20px !important;
	}
}

#modal-success {
	padding: 10px;
	text-align: center;
	h3 {
		font-family: $font-family-bold;
		font-size: 19px;
		width: 90%;
		margin: 0 auto;
		margin-bottom: 10px;
	}
	p {
		font-size: 17px;
		width: 90%;
		margin: 0 auto;
		margin-bottom: 10px;
	}
	img {
		width: 80px;
		height: 80px;
		margin-top: 10px;
		margin-bottom: 20px;
		object-fit: contain;
	}
	button {
		background-color: transparent !important;
		border: 0px !important;
		box-shadow: none !important;
		border-radius: 30px;
		outline: 0px !important;
		text-transform: uppercase;
		color: $public_green2;
		font-size: 23px;
		font-family: $font-family-bold;
		margin-bottom: 20px;
		margin-top: 10px;
	}
}