// View Category

#public-view-category {
	.container-header {
		background-size: cover;
		background-position: center center;
		width: 100%;
		position: relative;
		min-height: 400px;
		background-repeat: no-repeat;
		a {
			text-decoration: none !important;
			img {
				width: 300px;
				position: absolute;
				left: 40px;
				bottom: 40px;
			}
		}
	}
	#container-products {
		padding: 20px;
	}
	.item-slider {
		width: 100%;
	}
	h3.title-green {
		@include titleGreen();
		width: 100%;
		margin-top: 10px;
		margin-bottom: 30px;
	}
	.item-product {
		@include product();
	}
	a.link-product {
		text-decoration: none !important;
	}
	.message-no-items {
		width: 95%;
		border-radius: 10px;
		background-color: $prod-gray2;
		padding: 10px;
		text-align: center;
		font-family: $font-family-light;
		margin: 0 auto;
		margin-bottom: 30px;
	}
	.container-loading {
		text-align: center;
		color: $public_green2;
		margin-top: 40px;
		margin-bottom: 40px;
	}
	.container-item-filter {
		border: 1px solid $gray;
		padding: 5px;
		margin-top: 20px;		
		margin-bottom: 30px;
		.container-scroll {
			max-height: 400px;
			@include scroll();
			overflow-y: auto;
			overflow-x: hidden;
		}
		h3 {
			background-color: $gray;
			padding: 8px;
			font-size: 18px;
			font-family: $font-family-bold;
			text-align: left;
		}
		.btn-green {
			background-color: $public_green !important;
			border: 0px !important;
			box-shadow: none !important;
			border-radius: 30px;
			padding: 8px;
			color: $white !important;
			outline: 0px !important;
			padding-left: 25px;
			padding-right: 25px;
			width: 130px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 10px;
		}
		.btn-category {
			background-color: $gray !important;
			border: 0px !important;
			box-shadow: none !important;
			padding: 8px;
			color: $black !important;
			outline: 0px !important;
			padding-left: 15px;
			padding-right: 15px;
			justify-content: center;
			align-items: center;
			width: auto;
			display: inline-flex;
			border-radius: 0px !important;
			margin-right: 10px;
			margin-bottom: 5px;
			&.active {
				background-color: $public_green !important;
				color: $white !important;
			}
		}
		.btn-gray {
			background-color: transparent !important;
			border: 0px !important;
			box-shadow: none !important;
			padding: 8px;
			color: $black !important;
			outline: 0px !important;
			padding-left: 15px;
			padding-right: 15px;
			justify-content: center;
			align-items: center;
			width: 100%;
			display: block;
			border-radius: 0px !important;
			margin-right: 10px;
			text-align: left;
			transition: .5s;
			font-size: 14px;
			&.active {
				background-color: rgba(0,0,0,.1) !important;
			}
			&:hover {
				background-color: rgba(0,0,0,.1) !important;
			}
		}
		.price-filter-header {
			margin: 0px;
			margin-left: 5px;
		}
		.price-filter {
			font-family: $font-family-bold;
			font-size: 22px;
			margin: 0px;
			margin-left: 5px;
			margin-bottom: 10px;
		}
		.range-slider {
			width: 95%;
			margin: 0 auto;
			margin-top: 20px;
			margin-bottom: 20px;
			height: 2px;
			.range-slider__range {
				height: 2px;
				background-color: darken($gray,30);
			}
			.range-slider__thumb {
				background-color: $gray;
				border: 1px solid darken($gray,10);
				width: 10px;
				height: 10px;
				border-radius: 0px;
			}
		}
	}
	.container-filter {
		width: 90%;
		max-width: 289px;
		margin: 0 auto;
	}
}