// Public Footer

.btn-whatsapp-footer {
	background-color: transparent !important;
	border: 0px !important;
	outline: 0px !important;
	box-shadow: none !important;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
	& > div {
		background-color: #25d366;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		border-radius: 30px;
		padding-right: 20px;
		padding-left: 20px;
		@include shadow();
		img {
			margin-right: 10px;
			width: 20px;
			height: 20px;
			object-fit: contain;
			@include recolor($white);
		}
		p {
			color: $white;
			font-size: 20px;
			margin: 0px;
		}
	}
}

#public-footer {
	* {
		font-family: $font-family-regular;
	}
	background-color: $green2;
	padding: 20px;
	position: relative;
	z-index: 2;
	.row {
		margin: 0 auto;
		margin-top: -30px;
		h3 {
			color: $white;
			font-size: 16px;
			font-family: $font-family-black;
			margin-bottom: 25px;
			@media (max-width: 991px) {
				margin-top: 20px;
			}
		}
	}
	.item-address {
		display: flex;
		margin-bottom: 10px;
		img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			margin-right: 15px;
		}
		p {
			margin: 0px;
			color: $white;
			font-size: 16px;
		}
	}
	.link-social {
		text-decoration: none;
		img {
			width: 40px;
			height: 40px;
		}
	}
	ul {
		padding: 0px;
		list-style: none;
		li {
			margin-bottom: 7px;
		}
		a {
			color: $white !important;
			text-decoration: none;
			font-size: 14px;
		}
	}
	.logo-footer {
		max-width: 400px;
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	h3 {
		text-transform: uppercase;
		color: $white;
		font-size: 20px !important;
	}
	p.address {
		color: $white;
		max-width: 450px;
		font-size: 17px !important;
	}
	.copy {
		text-align: center;
		color: $white;
		margin-top: 25px;
		margin-bottom: 5px;
		font-size: 17px;
		strong {
			font-weight: normal;
			font-family: $font-family-regular;
		}
		&.limon {
			margin-top: 0px;
		}
	}
	.instagram-icon {
		width: 40px;
		height: 40px;
		object-fit: contain;
		margin-right: 10px;
	}
	.row-footer {
		max-width: 1000px;
		margin: 0 auto;
	}
	.container-whatsapp {
		display: flex;
		p {
			margin-bottom: 3px;
			color: $white;
			font-family: $font-family-regular;
			font-size: 18px;
		}
	}
	.link-limon {
		text-decoration: none !important;
		color: $white !important;
	}
}