// Public Header

#public-header {
	.logo {
		width: 250px;
		cursor: pointer;
		@media (max-width: 991px) {
			margin-bottom: 15px;
		}
	}
	.container-logo {
		display: flex;
		@media (max-width: 600px) {
			display: block;
			margin-bottom: 15px;
		}
	}
	.menu-normal {
		@media (max-width: 700px) {
			display: none;
		}
	}
	.menu-responsive {
		@media (min-width: 701px) {
			display: none;
		}
	}
	.header-orange {
		background-color: $new_orange;
		margin-bottom: 15px;
		p {
			margin: 0px;
			color: $white;
			text-transform: uppercase;
			text-align: center;
			font-family: $font-family-bold;
			padding: 5px;
			font-size: 13px;
		}
	}
	.btn-search {
		background-color: transparent !important;
		outline: 0px !important;
		border: 0px !important;
		box-shadow: none !important;
		margin-left: 10px;
		margin-top: 10px;
		position: relative;
		right: -10px;
		img {
			width: 30px;
			height: 30px;
		}
	}
	.container-buttons-top {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 20px;
		@media (max-width: 700px) {
			margin-top: 0px;
		}
		button {
			background-color: transparent !important;
			outline: 0px !important;
			box-shadow: none !important;
			border: 0px !important;
			text-transform: uppercase;
			font-family: $font-family-bold;
			font-size: 12px;
			color: darken($gray,30) !important;
			padding-right: 10px;
			padding-left: 10px;
			&:first-child:not(.without-border) {
				border-right: 1px solid darken($gray,30) !important;
			}
			img {
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}
		& > div {
			margin-left: 20px;
			cursor: pointer;
			img {
				width: 30px;
				height: 30px;
				object-fit: contain;
			}
		}
	}
	.container-buttons-menu {
		border-left: 1px solid darken($gray,20);
		margin-left: 20px;
		padding-left: 20px;
		padding-top: 5px;
		padding-bottom: 5px;
		flex-direction: row;
		align-items: center;
		height: 50px;
		margin-top: 20px;
		width: 100%;
		@media (max-width: 700px) {
			margin-top: 10px;
		}
		button {
			text-transform: uppercase;
			color: darken($gray,30) !important;
			background-color: transparent !important;
			border: 0px !important;
			box-shadow: none !important;
			outline: 0px !important;
			font-size: 12px;
			padding-left: 8px;
			padding-right: 8px;
			font-family: $font-family-bold;
			position: relative;
			top: 5px;
		}
	}
	.item-select-container {
		position: relative;
		background-color: transparent;
		width: 100%;
		box-shadow: none !important;
		outline: 0px !important;
		border: 0px !important;
		padding: 0px !important;
		.container-absolute {
			position: absolute;
			z-index: 999;
			top: 64px;
			width: 100%;
			background-color: $new_orange;
			max-height: 300px;
			overflow-y: auto;
			@include scroll();
			.item-select {
				background-color: $new_orange;
				width: 100%;
				margin: 0px;
				border-radius: 0px;
				transition: .5s;
				&:hover {
					background-color: lighten($new_orange,10);
				}
			}
		}
		& > .item-select {
			p {
				text-transform: uppercase;
				font-family: $font-family-bold;
			}
		}
		.item-select {
			padding: 5px;
			display: flex;
			align-items: center;
			margin-top: 15px;
			cursor: pointer;
			@include noSelect();
			p {
				margin: 0px;
				font-size: 16px;
				color: $white;
				margin-left: 5px;
			}
			img {
				margin-right: 10px;
				margin-left: 10px;
				width: 25px;
				height: 25px;
			}
		}
	}
	.container-orange {
		padding: 0px;
	}
	.row-header {
		background-color: $white;
		padding: 20px;
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
		margin: 0px !important;
		.container-whatsapp {
			display: flex;
			cursor: pointer;
			@include noSelect();
			@media (max-width: 991px) {
				margin-top: 10px;
				margin-bottom: 10px;
			}
			img {
				width: 50px;
				height: 50px;
				margin-right: 10px;
			}
			p {
				margin: 0px;
				color: $white;
				&.number {
					font-size: 20px;
				}
			}
		}
		.container-whatsapp-responsive {
			display: flex;
			justify-content: space-between;
			a {
				text-decoration: none;
			}
		}
	}
	.row-subheader {
		background-color: $public_green2;
		margin: 0px !important;
		.btn-white {
			background-color: transparent !important;
			box-shadow: none !important;
			outline: 0px !important;
			border: 0px !important;
			color: $white;
			font-size: 16px;
			padding: 0px;
			padding-left: 20px;
			padding-right: 20px;
			margin-top: 20px;
			margin-bottom: 20px;
			transition: .5s;
			text-transform: uppercase;
			font-family: $font-family-bold;
			border-right: 1px solid $white !important;
			&.without-border {
				border-right: 0px !important;
			}
			@media (max-width: 600px) {
				font-size: 14px;
			}
		}
	}
	.link-social {
		text-decoration: none;
		img {
			width: 40px;
			height: 40px;
		}
		&.link-social-responsive {
			margin-left: 25px;
			display: none;
			margin-top: 17px;
		}
		@media (max-width: 991px) {
			&:not(.link-social-responsive) {
				display: none;
			}
			&.link-social-responsive {
				display: block;
			}
		}
	}
	.container-scroll-products {
		@include scroll();
		max-height: 300px;
		overflow: auto;
	}
	.item-cart {
		display: inline-flex;
		color: $white;
		background-color: transparent !important;
		border: 0px !important;
		outline: 0px !important;
		box-shadow: none !important;
		position: relative;
		cursor: default;
		justify-content: center;
		align-items: center;
		@media (max-width: 991px) {
			position: initial;
		}
		.item-product-cart {
			display: flex;
			padding: 10px;
			&:not(:last-child) {
				border-bottom: 1px solid $gray;
			}
			img {
				width: 100px;
				height: 100px;
				border: 1px solid $gray;
			}
			p {
				color: $black;
				margin: 0px;
				font-size: 13px;
				text-align: left;
				margin-left: 10px;
				margin-rigth: 10px;
				span {
					font-size: 13px;
					font-family: $font-family-bold;
				}
				&.name {
					font-size: 14px;
					color: darken($gray,35);
				}
			}
		}
		.circle-yellow {
			display: flex;
			justify-content: center;
			align-items: center;
			color: $black;
			background-color: $yellow;
			padding: 3px;
			width: 20px;
			height: 20px;
			border-radius: 10px;
			margin-left: 5px;
			margin-right: 5px;
			position: relative;
			top: 3px;
			font-size: 11px;
		}
		img {
			width: 25px;
			height: 25px;
			object-fit: contain;
		}
		.container-cart-header {
			box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
			border-radius: 10px;
			border-top-right-radius: 0px;
			background-color: $white;
			z-index: 999;
			position: absolute;
			top: 65px;
			width: 400px;
			right: 0px;
			@media (max-width: 991px) {
				border-top-right-radius: 0px;
			}
			@media (max-width: 450px) {
				width: 100%;
			}
			.no-items {
				background-color: $gray;
				border-radius: 10px;
				color: $black;
				width: 95%;
				margin: 0 auto;
				margin-top: 10px;
				padding: 5px;
				font-family: $font-family-light;
				text-align: center;
			}
			.btn-yellow {
				background-color: $yellow !important;
				border: 0px !important;
				outline: 0px !important;
				box-shadow: none !important;
				text-transform: uppercase;
				color: $black !important;
				font-size: 17px;
				width: 150px;
				font-family: $font-family-bold;
				border-radius: 10px;
				margin-left: 10px;
				padding: 5px;
				text-align: center;
				cursor: pointer;
			}
			.btn-pay {
				background-color: $public_green2 !important;
				border: 0px !important;
				outline: 0px !important;
				box-shadow: none !important;
				text-transform: uppercase;
				color: $white !important;
				font-size: 17px;
				width: 150px;
				font-family: $font-family-bold;
				border-radius: 10px;
				margin-left: 10px;
				padding: 5px;
				text-align: center;
				cursor: pointer;
			}
			.container-buttons-cart {
				padding: 15px;
				text-align: right;
				border-top: 1px solid $gray;
				margin-top: 10px;
			}
			.subtotal {
				color: $black;
				font-size: 14px;
				font-family: $font-family-bold;
			}
		}
	}
	.container-input-search {
		width: 100%;
		margin-top: -5px;
		background-color: transparent !important;
		border: 0px !important;
		outline: 0px !important;
		box-shadow: none !important;
		cursor: default;
		.btn-autocomplete {
			background-color: transparent !important;
			border: 0px !important;
			outline: 0px !important;
			box-shadow: none !important;
			font-size: 13px;
			font-family: $font-family-light;
			width: 100%;
			text-align: left;
			height: auto !important;
			padding: 10px !important;
			img {
				margin-right: 10px;
			}
			&:hover {
				background-color: rgba(0,0,0,.1) !important;
				border-radius: 0px !important;
			}
			img {
				width: 30px;
				height: 30px;
				object-fit: contain;
				border-radius: 5px;
			}
		}
		.form-group {
			width: 100%;
			&.active {
				input {
					border-bottom-left-radius: 0px !important;
					border-top-left-radius: 10px !important;
				}
			}
		}
		.input-search {
			display: flex;
		}
		.autocomplete-list {
			background-color: $white;
			position: absolute;
			width: calc(100% - 135px);
			top: 40px;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
			z-index: 999;
		}
		input {
			border-radius: 5px !important;
			height: 40px;
			border: 0px !important;
			background-color: $public-gray !important;
		}
		button {
			padding: 0px !important;
			height: 40px;
			background-color: $public_green2 !important;
			border-top-right-radius: 5px !important;
			border-bottom-right-radius: 5px !important;
			border-top-left-radius: 0px !important;
			border-bottom-left-radius: 0px !important;
			margin-top: -5px;
			&.active {
				border-bottom-right-radius: 0px !important;
			}
			font-size: 15px;
			img {
				width: 17px;
				height: 17px;
				margin-right: 10px;
			}
		}
		button[type="submit"] {
			color: $white !important;
		}
	}
	.container-cart {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 45px !important;
			height: 45px !important;
			object-fit: contain;
		}
		p {
			margin: 0px;
			color: $new_orange;
			position: absolute;
			margin-top: 5px;
			font-size: 13px;
			font-family: $font-family-bold;
		}
	}
}