// Mixins

@mixin product() {
	width: 95%;
	margin: 0 auto;
	margin-bottom: 15px;
	padding: 0px;
	padding-top: 10px;
	text-align: center;
	@include shadow3();
	border-radius: 15px;
	overflow: hidden;
	@media (max-width: 991px) {
		width: 100%;
	}
	.container-price {
		width: 95%;
		margin: 0 auto;
	}
	.container-buttons-add {
		display: flex;
		margin-top: 10px;
	}
	h6 {
		color: darken($prod-gray4,10);	
		// font-family: $font-family-bold;
	}
	& > div {
		margin: 0 auto;
		text-align: left;
	}
	img {
		width: 100%;
		height: 200px;
		object-fit: contain;
		margin-bottom: 20px;
	}
	h4 {
		font-family: $font-family-black2;
		color: darken($prod-gray4,40);
		font-size: 18px;
	}
	.price {
		font-family: $font-family-black2;
		font-size: 18px;
		margin-top: 8px;
		margin-bottom: 8px;
		color: $green2;
	}
	button {
		background-color: lighten($prod-gray,10) !important;
		border: 0px !important;
		box-shadow: none !important;
		border-radius: 0px;
		padding: 8px;
		color: $white !important;
		outline: 0px !important;
		width: 100%;
		font-size: 14px;
		height: 40px;
		white-space: nowrap;
		&:hover {
			background-color: darken($public_green,5) !important;
		}
		img {
			width: 14px;
			height: 14px;
			object-fit: contain;
			margin-bottom: 0px;
			margin-right: 5px;
			position: relative;
			top: -2px;
			@include recolor($white);
		}
	}
	.existence {
		text-align: center;
		color: $danger !important;
		height: 40px;
		margin: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $gray;
		font-size: 14px;
	}
	.container-add {
		display: flex;
		background-color: $public-gray;
		height: 40px;
		margin-top: 0px;
		.form-group, input {
			height: 40px;
			width: 100%;
			text-align: center;
			border-radius: 0px !important;
			padding: 0px !important;
			margin-bottom: 0px;
			background-color: transparent !important;
			border: 0px !important;
		}
		input {
			border: 0px solid $black !important;
		}
		button {
			background-color: transparent !important;
			box-shadow: none !important;
			border: 0px solid $black !important;
			outline: 0px !important;
			padding: 0px;
			padding-right: 5px;
			padding-left: 5px;
			height: 40px;
			font-size: 14px;
			p {
				font-family: $font-family-bold;
				font-size: 30px;
				margin: 0px;
				padding: 0px;
				position: relative;
				top: -5px;
			}			
			&.btn-sub-quantity {
				margin-right: 10px;
				p {
					color: darken($gray,30);
				}
			}
			&.btn-add-quantity {
				margin-left: 10px;
				p {
					top: -3px;
					color: darken($gray,30);
				}
			}
		}
	}
}

@mixin combo() {
	border: 4px solid $prod-gray2;
	margin: 0 auto;
	margin-bottom: 15px;
	padding: 10px;
	display: flex;
	margin-right: 5px;
	margin-left: 5px;
	.container-buttons-add {
		display: flex;
		margin-top: 10px;
	}
	.container-add {
		display: flex;
		background-color: $public-gray;
		max-width: 300px;
		.form-group, input {
			height: 40px;
			width: 100%;
			text-align: center;
			border-radius: 0px !important;
			padding: 0px !important;
			margin-bottom: 0px;
			background-color: transparent !important;
			border: 0px !important;
		}
		input {
			border: 0px solid $black !important;
		}
		button {
			background-color: transparent !important;
			box-shadow: none !important;
			border: 0px solid $black !important;
			outline: 0px !important;
			padding: 0px;
			padding-right: 15px;
			padding-left: 15px;
			height: 40px;
			p {
				font-family: $font-family-bold;
				font-size: 30px;
				margin: 0px;
				padding: 0px;
				position: relative;
				top: -5px;
			}			
			&.btn-sub-quantity {
				margin-right: 10px;
				p {
					color: darken($gray,30);
				}
			}
			&.btn-add-quantity {
				margin-left: 10px;
				p {
					top: -3px;
					color: darken($gray,30);
				}
			}
		}
	}
	img {
		width: 200px;
		height: 200px;
		object-fit: contain;
		margin-right: 10px;
	}
	& > div {
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	h4 {
		font-family: $font-family-bold;
		color: $public_green2;
		font-size: 18px;
	}
	.price {
		font-family: $font-family-bold;
		font-size: 22px;
		margin-top: 15px;
		margin-bottom: 15px;
		color: $black;
	}
	button {
		background-color: lighten($prod-gray,10) !important;
		border: 0px !important;
		box-shadow: none !important;
		border-radius: 0px;
		padding: 8px;
		color: $white !important;
		outline: 0px !important;
		width: 100%;
		&:hover {
			background-color: darken($public_green,5) !important;
		}
		img {
			width: 17px;
			height: 17px;
			object-fit: contain;
			margin-bottom: 0px;
			margin-right: 5px;
			position: relative;
			top: -2px;
		}
	}
	.existence {
		text-align: center;
		color: $danger !important;
		height: 40px;
		margin: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $gray;
	}
}

@mixin titleGreen() {
	border-bottom: 3px solid $public_green;
	padding-bottom: 5px;
	font-size: 22px;
	font-family: $font-family-bold;
	margin-top: 30px;
	margin-bottom: 20px;
	width: 95%;
	@media (max-width: 991px) {
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}

@mixin recolor($color: #000, $opacity: 1) {
  $r: red($color) / 255;
  $g: green($color) / 255;
  $b: blue($color) / 255;
  $a: $opacity;

  // grayscale fallback if SVG from data url is not supported
  $lightness: lightness($color);
  filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);

  // color filter
  $svg-filter-id: "recolor";
  filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');
}