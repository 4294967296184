@font-face {
    font-family: $font-family;
    src: url($fonts-path + $font-family + ".ttf");
}

@font-face {
    font-family: $font-family-bold;
    src: url($fonts-path + $font-family-bold + ".ttf");
}

@font-face {
    font-family: $font-family-light;
    src: url($fonts-path + $font-family-light + ".ttf");
}

@font-face {
    font-family: $font-family-black;
    src: url($fonts-path + $font-family-black + ".ttf");
}

@font-face {
    font-family: $font-family-regular;
    src: url($fonts-path + $font-family-regular + ".ttf");
}

@font-face {
    font-family: $font-family-black2;
    src: url($fonts-path + $font-family-black2 + ".ttf");
}