// Product Slider

#public-product-slider {
	background-color: $gray;
	padding: 20px;
	padding-right: 30px;
	padding-left: 30px;
	padding-top: 10px;
	padding-bottom: 30px;
	a {
		text-decoration: none !important;
	}
	.item-product {
		@include product();
		background-color: $white;
	}
	.container-title-slide {
		@include titleGreen();
		width: 100%;
		h3 {
			font-size: 22px;
			font-family: $font-family-bold;
		}
		display: flex;
		justify-content: space-between;
		& > div {
			position: relative;
			bottom: 5px;
		}
		button {
			background-color: transparent !important;
			box-shadow: none !important;
			border: 0px !important;
			outline: 0px !important;
			margin-left: 10px;
			img {
				width: 20px;
				height: 20px;
				object-fit: contain;
			}
		}
	}
}