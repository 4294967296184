// Title Modal Right

.title-modal-right {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	padding: 20px;
	padding-bottom: 10px;
	h3 {
		font-family: $font-family-bold;
		color: $blue;
		margin: 0px !important;
		font-size: 22px;
	}
	.btn-close {
		img {
			width: 18px;
			height: 18px;
		}
	}
}