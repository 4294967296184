// Table

.table-component {
	background-color: transparent;
	overflow: hidden;
	background-color: $white;
	margin-bottom: 0px;
	@media (max-width: 767px) {
		min-width: 500px;
	}
	thead {
		border: 0px !important;
		background-color: $blue;
		border-bottom: 1px solid $prod-gray3;
		th {
			border: 0px !important;
			color: $white;
			font-family: $font-family-bold;
			font-size: 16px;
			border-right: 1px solid $white !important;
			&:last-child {
				border-right: 0px !important;
			}
			&:first-child {
				border-right: 0px !important;
			}
		}
		&.thead-responsive {
			display: none;
		}
		tr {
			border-bottom: 1px solid darken($prod-gray,20) !important;
		}
	}
	tbody {
		border: 0px !important;
		tr {
			border: 0px !important;
			border-bottom: 1px solid lighten($prod-gray,40) !important;
			&:hover {
				background-color: lighten($gray,10);
			}
			&:last-child {
				border-bottom: 0px !important;
			}
		}
		td {
			border: 0px;
			font-size: 14px;
			button {
				margin-right: 5px;
			}
		}
	}
	td,th {
		font-size: 12px;
	}
}

.table-component.table-color {
	cursor: pointer;
}

.no-table-data {
	font-size: 16px;
	border-radius: 2px;
	text-align: center;
	padding: 10px;
	margin-bottom: 0px !important;
	padding: 25px;
}

.table-container-component {
	background-color: $white;
	border-radius: 20px;
	width: 100%;
	margin: 0 auto;
	overflow: auto;
	padding-bottom: 0px;
	margin-top: 10px;
	@include shadow2();
	hr {
		display: none;
	}
}