// View Product

#public-view-product {
	.container-slider-secondary {
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.container-image {
		margin-bottom: 30px;
		display: flex;
		justify-content: center;
		@media (max-width: 991px) {
			display: none;
		}
		img {
			border: 1px solid $gray;
		}
	}
	.image-responsive {
		display: none;
		text-align: center;
		margin-bottom: 30px;
		img {
			border: 1px solid $gray;
			max-width: 370px;
			width: 100%;
		}
		@media (max-width: 991px) {
			display: block;
		}
	}
	.row-info {
		margin: 0 auto;
		margin-top: 30px;
		margin-bottom: 30px;
		max-width: 1100px;
	}
	.image-preview {
		width: 100px;
		height: 100px;
		border: 1px solid $gray;
	}
	.large-image {
		z-index: 999;
	}
	.container-photos {
		display: flex;
		.slide-photos {
			text-align: center;
		}
		.swiper-wrapper {
			min-width: 100px;
		}
		button {
			background-color: transparent !important;
			box-shadow: none !important;
			outline: 0px !important;
			border: 0px !important;
		}
	}
	.total {
		font-family: $font-family-bold;
		font-size: 18px;
		margin-bottom: 3px;
	}
	.container-quantity {
		button.btn-add {
			background-color: $public_green !important;
			border: 0px !important;
			box-shadow: none !important;
			border-radius: 0px;
			padding: 8px;
			color: $white !important;
			outline: 0px !important;
			padding-left: 25px;
			padding-right: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0px;
			margin-top: 15px;
			width: 100%;
			max-width: 300px;
			img {
				width: 17px;
				height: 17px;
				object-fit: contain;
				margin-bottom: 0px;
				margin-right: 5px;
				position: relative;
				top: 1px;
			}
		}
	}
	.price {
		font-family: $font-family-bold;
		font-size: 25px;
		color: $public-green;
		margin-top: 5px;
	}
	h2 {
		font-family: $font-family-bold;
		font-size: 22px;
		padding-bottom: 0px;
		margin-bottom: 0px;
		color: darken($gray,40);
	}
	h4 {
		color: $public_green2;
		font-family: $font-family-bold;
		font-size: 17px;
		margin-bottom: 15px;
	}
	.page-name {
		margin: 0px;
		font-size: 13px;
		color: darken($gray,30);
	}
	.container-add {
		display: flex;
		background-color: $public-gray;
		max-width: 300px;
		margin-top: 15px;
		.form-group, input {
			height: 40px;
			width: 100%;
			text-align: center;
			border-radius: 0px !important;
			padding: 0px !important;
			margin-bottom: 0px;
			background-color: transparent !important;
			border: 0px !important;
		}
		input {
			border: 0px solid $black !important;
		}
		button {
			background-color: transparent !important;
			box-shadow: none !important;
			border: 0px solid $black !important;
			outline: 0px !important;
			padding: 0px;
			padding-right: 15px;
			padding-left: 15px;
			height: 40px;
			p {
				font-family: $font-family-bold;
				font-size: 30px;
				margin: 0px;
				padding: 0px;
				position: relative;
				top: -5px;
			}			
			&.btn-sub-quantity {
				margin-right: 10px;
				p {
					color: darken($gray,30);
				}
			}
			&.btn-add-quantity {
				margin-left: 10px;
				p {
					top: -3px;
					color: darken($gray,30);
				}
			}
		}
	}
	.existence {
		color: $black;
		font-size: 13px;
		&.out {
			color: $danger;
		}
	}
	.btn-share {
		background-color: transparent !important;
		outline: 0px !important;
		border: 0px !important;
		box-shadow: none !important;
		color: darken($gray,20);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		font-size: 13px;
		img {
			width: 15px;
			height: 15px;
			object-fit: contain;
			margin-right: 5px;
		}
	}
	.long-description {
		font-size: 14px;
		width: 90%;
		margin: 0 auto;
		margin-bottom: 0px;
		margin-top: 20px;
	}
}